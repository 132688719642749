import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Dialog, Snackbar } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import CheckIcon from "@material-ui/icons/Check";

import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    direction: "ltr !important",
  },
  label2: {
    fontFamily: "IRANSans",
    direction: "ltr !important",
    "& .MuiAlert-icon": {
      marginRight: 10,
      marginLeft: 10,
    },
  },
}));
const DialogSuccess = (props) => {
  const classes = useStyles();
  const { isResult, handleCloseDialog } = props;
  return (
    
    <>
    </>
        // <Snackbar
        //   open={isResult.result}
        //   autoHideDuration={1500}
        //   onClose={handleCloseDialog}
        //   className={classes.root}
        // >
        //   <Alert severity="success" className={classes.label2}>
        //     {isResult.msg}
        //   </Alert>
        // </Snackbar>
      
  );
};
DialogSuccess.propTypes = {
  isResult: PropTypes.object.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
};
export default DialogSuccess;
