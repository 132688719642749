 


export const RELOAD_PAGE = "RELOAD_PAGE";


export const ADD_WINNER = "ADD_WINNER";
export const EDIT_WINNER = "EDIT_WINNER";
export const DELETE_WINNER = "DELETE_WINNER";


export const PAGE_TABLE_HOME = "PAGE_TABLE_HOME";
export const DELETE_LOTTERY = "DELETE_LOTTERY";
export const ARCHIVE_LOTTERY = "ARCHIVE_LOTTERY";