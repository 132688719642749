import React from 'react'
import PropTypes from 'prop-types';
 
import HeaderTable from "./HeaderTable";
import BodyTable from "./body/BodyTable";
import Footer from './footer/Footer';
import {tableStyle,headerStyle} from './style/style';
import AddIcon from '@material-ui/icons/Add';
import {
    TableContainer,
    Grid,
    Table,
    Paper,
    TextField,
    Button
  } from "@material-ui/core";
import { Link } from 'react-router-dom';





 
export let showChild=0;
const TableView=(props )=> {
    const classes = tableStyle();   
    const { rows,DataHeader ,RowTable,countColumn,isFooter,totalPages,countRowsShow,loading} = props; 
    
    const [addLottery,setAddLottery]=React.useState(false);
    
  
    return (
        <Grid container className={`${classes.layoutTable} ${loading&& 'blurBackground'}`} direction="row" >
          
        
      <Grid
        container
        direction="row"
        xs={12}
        className={classes.layoutTitle}
        alignItems="center"
        justify="space-between"
      >
        <Grid item className="titleTable" style={{color:"#000"}}> لیست قرعه کشی های انجام شده </Grid>
        <Grid container direction="row" xs={6} spacing={2} style={{paddingLeft:"15px"}} justify="flex-end" alignContent='center' alignItems="center">
         
       
         
         
        </Grid>
      </Grid>
      
       
        
        <TableContainer component={Paper} className={classes.root}>
          <Table aria-label="collapsible table" size="small"  className={classes.table} >
            <HeaderTable listHeader={DataHeader} theme={headerStyle()} />
            <BodyTable countRowsShow={countRowsShow} listRow={rows}   RowTable={RowTable}   />
            <Footer  
               countPage={totalPages}
               countColumn={countColumn}
             
               />
          </Table>
        </TableContainer>
      </Grid>
    );
}

TableView.propTypes = {
    rows: PropTypes.array.isRequired,
    countColumn:PropTypes.number.isRequired,
    totalPages:PropTypes.number,
    DataHeader:PropTypes.array.isRequired,   
    RowTable: PropTypes.object.isRequired, 
    isFooter:PropTypes.bool.isRequired,
    countRowsShow:PropTypes.number.isRequired,
    editStatus:PropTypes.func.isRequired,
    listPerPage:PropTypes.array.isRequired,
  };

  TableView.defaultProps = {
    isFooter: true, 
  };
export default TableView;