import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  TableRow,
  IconButton,
  Grid,
  withStyles,
  Tooltip,
  Button,
} from "@material-ui/core";
import VideocamIcon from "@material-ui/icons/Videocam";
import WinnerDialog from "../../modal/WinnerDialog";

import MuiTableCell from "@material-ui/core/TableCell";
import { tableRowOne, focusDisable, unSelect } from "../style/style";
import { showChild } from "../TableView";
import { connect } from "react-redux";
import {
  deleteLotteryAction,
  archiveLotteryAction,
} from "../../../../redux/actions";

const TableCell = withStyles((theme) => ({
  root: {
    borderBottom: "none",
  },
}))(MuiTableCell);

const useRowStyles = makeStyles({
  root: {
    background: "#f0f0f0",
    WebkitTransition: "background-color 100ms linear",
    msTransition: "background-color 100ms linear",
    transition: "background-color 100ms linear",
    // border:"1px solid #f7f7f7",

    "& th,  td": {
      borderBottom: 0,
    },
    "&:hover": {
      //  background: "rgba(245, 0, 87, 0.12)",
      //  border:"1px solid rgba(245, 0, 87, 0.15)",
      //  borderBottom:"1px solid white",
    },
    "& > *": {
      fontSize: 12,
      fontFamily: "title2",
      color: "dark",
      top: 0,
      bottom: 0,
    },
  },
  label: {
    color: "#757575",
  },
});

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  tableRow: {
    background: "#000",
    "& th,  td": {
      borderBottom: 0,
    },
  },
});

const useRowStyles2 = makeStyles({
  root: {
    background: "transparent",
    //  border:"1px solid #fff",
    ///  border:"1px solid white",
    // WebkitTransition: "background-color 100ms linear",
    // msTransition: "background-color 100ms linear",
    // transition: "background-color 100ms linear",
    // borderBottom:"1px solid transparent",
    minHeight: "50px !important",

    "& th,  td": {
      // borderBottom: 0,
    },
    "&:hover": {
      // background: "rgba(245, 0, 87, 0.12)",
      //  background: "green",
      // border:"1px solid rgba(245, 0, 87, 0.15)",
      // borderBottom:"1px solid #f7f7f7",
    },
    "& > *": {
      fontSize: 11,
      fontFamily: "title2",
      color: "#000",
    },
  },
  label: {
    color: "#cfb53b",
  },
  btn1: {
    fontFamily: "IRANSans",
    "&:focus": {
      outlineStyle: "none!important",
    },
    "&:hover": {
      background: "#4caf5040",
    },
    "& span": {
      color: "#4caf50",
      fontSize: "13px",
    },
    background: "#4caf5030",
  },

  btn2: {
    fontFamily: "IRANSans",
    "&:focus": {
      outlineStyle: "none!important",
    },
    "&:hover": {
      background: "#d32f2f40",
    },
    "& span": {
      color: "#d32f2f",
      fontSize: "13px",
    },
    background: "#d32f2f30",
  },
});

const textBtn = "ثبت در آرشیو";
const textBtn2 = "حذف از آرشیو";

const RowTable = (props) => {
  const {
    row,
    classes,
    id_row,
    deleteLotteryAction,
    archiveLotteryAction,
  } = props;
  const [open, setOpen] = React.useState(false);
  // const classes = styles();
  const rowS = useRowStyles2();
  const rowS2 = useRowStyles();
  const fucuseDis = focusDisable();
  const unSelect1 = unSelect();
  const [showDialogWinner, setShowDialogWinner] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState({
    result: false,
    msg: "",
    isSuccess: false,
  });
  const handleCloseWrringDialog = () => {
    setOpenSnack({ ...openSnack, result: false });
    setTimeout(() => {
      deleteLotteryAction(row);
    }, 20);

    //  window.sessionStorage.setItem("lottery",JSON.stringify(todos.lottery))
    //     let da_= data.filter(function(ele){
    //              return ele != todos.winner ;
    //          });
    //  setData(da_)
    //  setOpenSnack({ ...openSnack, result: false });
  };

  return (
    <>
      <TableRow className={(id_row + 1) % 2 == 0 ? rowS.root : rowS2.root}>
        <TableCell align="center" size="small" style={{ fontSize: 14 }}>
          {id_row + 1}
        </TableCell>
        <TableCell
          component="td"
          variant="body"
          align="center"
          style={{ fontFamily: "IRANSans", fontSize: 14 }}
        >
          {" "}
          {row.idLottery}{" "}
        </TableCell>
        <TableCell align="center" style={{ minWidth: 150, fontSize: 14 }}>
          {row.nameLottery}
        </TableCell>
        <TableCell align="center" style={{ direction: "ltr", fontSize: 14 }}>
          {row.time}
        </TableCell>

        <TableCell align="center" style={{ direction: "ltr", fontSize: 14 }}>
          {row.countWinner}
        </TableCell>

        <TableCell align="center">
          <Grid container direction="row" justify="center">
            <IconButton
              className={fucuseDis.root}
              aria-label="expand row"
              size="small"
              onClick={() => {
                setShowDialogWinner(true);
              }}
            >
              <VisibilityIcon
                style={{
                  color: "#4caf50",
                  background: "#4caf5030",
                  borderRadius: 50,
                  padding: 5,
                  fontSize: 23,
                }}
              />
            </IconButton>
            {row.video && (
              <a href={row.video} target="_blank">
                <IconButton
                  className={fucuseDis.root}
                  aria-label="expand row"
                  size="small"
                >
                  <VideocamIcon
                    style={{
                      color: "#e91e63",
                      background: "#e91e6330",
                      borderRadius: 50,
                      padding: 5,
                      fontSize: 23,
                    }}
                  />
                </IconButton>
              </a>
            )}
          </Grid>
        </TableCell>
      </TableRow>

      <WinnerDialog
        showDialog={showDialogWinner}
        handleCloseDialog={() => setShowDialogWinner(false)}
        idLottery={row.idLottery}
      />
    </>
  );
};

RowTable.propTypes = {
  id_row: PropTypes.number.isRequired,
  row: PropTypes.shape({
    date: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
    source_card_number: PropTypes.string.isRequired,
    destination_card_number: PropTypes.string.isRequired,
    user_level: PropTypes.number.isRequired,
    transaction_status: PropTypes.string.isRequired,
    port_type: PropTypes.string.isRequired,
    destination_card_type: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(null, { archiveLotteryAction, deleteLotteryAction })(
  withStyles(styles)(RowTable)
);
