import React from "react";

import "./styles/App.scss";
import "./styles/app.css";
import HomePage from "./views/home/HomePage";
  
export default function App() {
  return (
    <>
       <HomePage  />
     
    </>
  );
}
