import React from "react";

const LoadingProcess2 = ({ loading }) => {
  return (
    <>
      {loading && (
        <div className=" backgroundPostionProcess2">
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoadingProcess2;
