import {
    EDIT_WINNER,DELETE_WINNER, ADD_WINNER
} from '../actionTypes'


const infoState = {
    winner: null ,
    type:"add",
};

const AddLotteryReducer = (state = infoState, action) => {

 
    switch (action.type) {
        case ADD_WINNER: {
            const { 
                winner,type
                
            } = action.payload;
            return { 
                winner: winner, 
                 type:type
            };
        }
        case EDIT_WINNER: {
            const { 
                winner,type
                
            } = action.payload;
            return { 
                winner: winner, 
                type:type
            };
        }
        case DELETE_WINNER: {
            const { 
                winner,type
                
            } = action.payload;
            return { 
                winner: winner, 
                type:type
            };
        }
        default: {
            return state;
        }
    }

}

export default AddLotteryReducer;