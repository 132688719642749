import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
  StylesProvider,
  jssPreset,
  withStyles
} from "@material-ui/core/styles";
import {
  create
} from "jss";
import rtl from "jss-rtl";

export const headerStyle = makeStyles(() => ({
  root: {
    //  background: '#ebeef5',
    background: "linear-gradient(180deg, #353535 30%, #1d1d1d 90%)",
  },
  label: {
    fontFamily: "IRANSans",
    color: "#fff",
    fontWeight: 500,
    fontSize: 12,
    textAlign: "center",
    userSelect: "none",
    msUserSelect: "none",
    MozUserSelect: "none",
    WebkitTouchCallout: "none",
  },
}));

// export const headerStyle = makeStyles(() => ({
//   root: {
//     //  background: '#ebeef5',
//     background: "linear-gradient(180deg, #353535 30%, #1d1d1d 90%)",
//   },
//   label: {
//     fontFamily: "IRANSans",
//     color: "white",
//     fontWeight: 500,
//     fontSize: 12,
//     textAlign: "center",
//     userSelect: "none",
//     msUserSelect: "none",
//     MozUserSelect: "none",
//     WebkitTouchCallout: "none",
//   },
// }));
export const tableStyle_mine = makeStyles({
  root: {
     //  margin: 10,   
      // background:"#141319  !important",
      // padding:15,
      background:"transparent  !important",
      marginRight:10,
      marginLeft:10,
      boxShadow:"none !important",
      border:"none !important",
      outline:"none !important",
       height:"320px"
     
  },
  table:{
      background:"transparent  !important",
   //   padding:"15px !important",
     // borderRadius:"12px"
     boxShadow:"none !important",
     border:"none !important",
     outline:"none !important",
     borderSpacing:"0px"
  },
  layoutTitle:{
   fontFamily:"title2",
   borderBottom:"1px solid rgba(173, 173, 173, 0.3)",
   paddingLeft:"10px",
   color:"#fff",
   marginTop:"10px",
   paddingBottom:"10px",
   fontSize:"14px",
   fontWeight:"500",
   marginBottom:"15px"
  },
  layoutTable:{
    background:"#fff",
    borderRadius:"8px"
  },
  smallIcon:{
    width: "40px",
    height: "40px",
    padding:"9px", 
    marginRight:"10px",
    marginLeft:"10px"
  },

  
});
export const tableStyle = makeStyles({
  root: {
     //  margin: 10,   
      // background:"#141319  !important",
      // padding:15,
      background:"transparent  !important",
      marginRight:10,
      marginLeft:10,
      boxShadow:"none !important",
      border:"none !important",
      outline:"none !important",
    //   height:"320px"
     
  },
  layoutBoxDashboard:{ 
    background:"#141319", 
},
  table:{
      background:"transparent  !important",
   //   padding:"15px !important",
     // borderRadius:"12px"
     boxShadow:"none !important",
     border:"none !important",
     outline:"none !important",
     borderSpacing:"0px"
  },
  layoutTitle:{
   fontFamily:"title2",
   borderBottom:"1px solid rgba(173, 173, 173, 0.3)",
   paddingLeft:"10px",
   color:"#fff",
   marginTop:"10px",
   paddingBottom:"10px",
   fontSize:"14px",
   fontWeight:"500",
   marginBottom:"15px"
  },
  layoutTable:{
    background:"#fff",
    borderRadius:"8px"
  },
   btn: { 
    fontFamily: "IRANSans",
    "&:focus": {
      outlineStyle: "none!important",
    },
    "& span": {
      color: "#007bff ",
      fontSize:"13px"
    },
    background: "#007bff30",
    "&:hover": {
      background: "#007bff40",
    },
    "&.Mui-disabled": {
      background: "#007bff30",
    },
  },
  btn1: { 
    fontFamily: "IRANSans",
    "&:focus": {
      outlineStyle: "none!important",
    },
    "& span": {
      color: "#4caf50",
      fontSize:"13px"
    },
    background: "#4caf5030",
    "&:hover": {
      background: "#4caf5040",
    },
    "&.Mui-disabled": {
      background: "#066b3b",
    },
  },
 
  btn2:{
    fontFamily: "IRANSans",
    "&:focus": {
      outlineStyle: "none!important",
    },
    "&:hover": {
      background: "#d32f2f40",
    },
    "& span": {
      color: "#d32f2f",
      fontSize:"13px"
    },
    background: "#d32f2f30", 
    "&.Mui-disabled": {
      background: "#d32f2f30",
    },
  }

  
});
export const topMargin = makeStyles({
  root: {
    marginTop: 0,
    marginBottom: 12,
    marginRight:0
  },
});


export const layoutStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export const theme1 = createMuiTheme({
  direction: "rtl", // Both here and <body dir="rtl">
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#0ea960',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0ea960',
      main: '#0ea960',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#fff',
    },
    
  },
  "&:focus":{
    outlineStyle:"none!important"
  },


});
export const jss = create({
  plugins: [...jssPreset().plugins, rtl()]
});

 export const footerViewStyle = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginRight: theme.spacing(2.5),
     
  },
}));

export const headerStyle2 = makeStyles((bg) => ({
  root: {
    background: '#B2EBF2',
  },
  label: {
    fontFamily: 'IRANSans',
    color: 'dark',
    fontWeight: 500,
    fontSize: 10,
    textAlign: 'center',
    userSelect: 'none',
    msUserSelect: 'none',
    MozUserSelect: 'none',
    WebkitTouchCallout: 'none'

  },
}));

export const rowChild = makeStyles((bg) => ({
  root: {
    background: '#80DEEA',
  },
  label: {
    fontFamily: 'IRANSans',
    color: 'dark',
    fontWeight: 500,
    fontSize: 10,
    textAlign: 'center',
    userSelect: 'none',
    msUserSelect: 'none',
    MozUserSelect: 'none',
    WebkitTouchCallout: 'none'

  },
}));
export const tableStyle2 = makeStyles({
  root: {
    background: '#f2f2f2',
  },
  checkBoxLayout:{

    "& span:first-child":{ 
      fontSize:"8px !important",
      color:"#007bff !important",
      margin:"0px",
      padding:"0px",
      marginRight:"2px"
      
     


    }, 
    "& span ":{
      fontFamily:"title2",
      fontSize:"10px",
      color:"#fff !important"
     


    }, 
    padding:0,
    margin:0,
    maxHeight:"20px", 

  },
});



export const tableRowOne = makeStyles({
  root: {
    // width: "100%",
    // // marginTop: theme.spacing.unit * 3,
    // overflowX: "auto",

    "&:hover": { 
      borderRight: '10px solid  #000', 

    },
    "& > *": { 
      fontSize:12,
       fontFamily:'IRANSans',
    },
  },
  table: {
    minWidth: 700
  },
  tableRow: {
    
    background:'#000',
    "& th,  td": {
      borderBottom: 0
    },
    "& tr:hover": { 
      borderRight: '10px solid  #000', 

    },
    "& > *": { 
      fontSize:12,
       fontFamily:'IRANSans',
    },
    
  }
});
export const togleBtn = withStyles({
  root: {
   
      "&:focus":{
        outlineStyle:"none!important"
      },
      "&  button:focus":{
        outlineStyle:"none"
      },

  },
  selected:{
    color: '#f50057',
   
    border: '1px solid #f50057',
    background: 'rgba(245, 0, 87, 0.12)',
    // "& span":{
    //   color: '#f50057',
    // }
  },
})
export const focusDisable = makeStyles({
  root: {
    fontFamily: "IRANSans",
    fontSize:10,
      "&:focus":{
        outlineStyle:"none!important"
      },
      "&  button:focus":{
        outlineStyle:"none"
      },

  },
  selected:{
    color: '#f50057',
   
    border: '1px solid #f50057',
    background: 'rgba(245, 0, 87, 0.12)',
    // "& span":{
    //   color: '#f50057',
    // }
  },
   
  ul: {
     
    marginLeft:40, 
    "&  button:focus":{
      outlineStyle:"none"
    },
    // "& button:first-child":{  
    //   transform: "rotate(180deg)",
    // },
    // "& button:last-child":{
    //   transform: "rotate(180deg)",
    // }
    "& button":{ 
      color:"#676669",
      border:"1px solid #676669",
    },
    "& div":{
      color:"#676669"
    },
    "& button > svg":{  
      color:"#fff", 
     
    //  transform: "rotate(180deg)",
     // direction:'ltr',
    },
    // "& button[tabindex='0']":{  
    //   direction:'ltr'
    //  // transform: "rotate(180deg)",
    // },
    borderColor:"#adadad", 
},

});


export const unSelect = makeStyles({
  root: {
    
    unSelect:'none',
    userSelect:'none',
    msUserSelect:'none',
    MozUserFocus:'none',
 
      
  },
});
