import axios from "axios";
 
const instance = axios.create({
  withCredentials: true,
  credentials: "include",   
  baseURL: "https://cheetlottery.pw/lottery/api/user/",   
});

export default {
  getRandomIdLottery: ( ) =>
  instance({
    method: "GET",
    url: "getRandomIdLottery.php",
   
  }),
  getAddLottery: (values ) =>
  instance({
    method: "POST",
    url: "addLottery.php",
    data: values,
    
  }),

  getListLottery: (values ) =>
  instance({
    method: "POST",
    url: "getListLottery.php",
    data: values,
    
  }),

  getListWinner: (values ) =>
  instance({
    method: "POST",
    url: "getListWinner.php",
    data: values,
    
  }),

  deleteLottery: (values ) =>
  instance({
    method: "POST",
    url: "updateLottery.php",
    data: values,
    
  }),
};
