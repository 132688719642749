import React, { useState } from "react";
import PropTypes from "prop-types";

import HeaderTable from "./HeaderTable";
import BodyTable from "./body/BodyTable";
import { tableStyle, headerStyle } from "./style/style";

import FilterTiltShiftIcon from "@material-ui/icons/FilterTiltShift";
import {
  TableContainer,
  Grid,
  Table,
  Paper,
  IconButton,
  Button,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close"; 
import LoadingProcess from "../../../LoadingProcess2";
import { connect } from "react-redux";
import {message} from '../DataTable'




export let showChild = 0;




const TableView = (props) => {
  const classes = tableStyle();
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const {
    rows,
    DataHeader,
    RowTable,
    handleCloseDialog, 
    updateShowCPGQueue, 
  } = props;
  const [openDialog, setOpenDialog] = React.useState({
    result: false,
    type: -1,
  });
  const [isResultRequest, setIsResultRequest] = useState({
    result: false,
    msg: "",
    isSuccess: 0,
  });
  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const handleClickOpen = (type) => () => {
    handleCloseDialog();
    setTimeout(() => {
      setOpenDialog({ result: true, type: type });
    }, 200);
  };
  const handleRemove= () => {
    //  handleCloseDialog()
    setLoading2(true); 
  };
  const handleMove= () => {
    //  handleCloseDialog()
    setLoading(true); 
  };
  const handleCloseDialogSuccess=()=>{
    setIsResultRequest({...isResultRequest,result:false})
      updateShowCPGQueue(true)
    handleCloseDialog()
    
  }

  
 
  return (
    <Grid
      container
      className={`${classes.layoutTable} `}
      direction="row"
      justify="center"
   //   alignItems="flex-start" alignContent="flex-start"
    >
      <Grid
        container
        direction="row"
        xs={12}
        className={classes.layoutTitle}
        alignItems="center"
      //  justify="space-between"
        style={{
          position: "-webkit-sticky",
          position: "sticky",
          top: 0,
          background: "#fff",
        }}
      >
         <Grid
          container
          justify="flex-start"
          alignContent="center"
          alignItems="center"
          xs={1}
        >
          <IconButton
            className={classes.icoBtn}
            aria-label="expand row"
            size="small"
            onClick={handleCloseDialog}
            className="icon_btn_deposit"
          >
            <CloseIcon style={{ color: "#525252", fontSize: "30px" }} />
          </IconButton>
        </Grid>
        <Grid
          container
          justify="center"
          alignContent="center"
          alignItems="center"
          xs={11}
          className="titleTable"
          style={{ textAlign: "center" }}
        >
        
         <Grid item xs={10} style={{color:"#000",fontFamily:"title2"}}>  لیست برندگان  </Grid>
         <Grid item xs={1}></Grid>
        </Grid>  
        
      
      </Grid>

      <TableContainer component={Paper} className={classes.root}>
        <Table
          aria-label="collapsible table"
          size="small"
          className={classes.table}
        >
          <HeaderTable listHeader={DataHeader} theme={headerStyle()} />
          <BodyTable listRow={rows} RowTable={RowTable} />
        </Table>
      </TableContainer>

       
     
     
    </Grid>
  );
};

TableView.propTypes = {
  rows: PropTypes.array.isRequired,
  countColumn: PropTypes.number.isRequired,
  totalPages: PropTypes.number,
  DataHeader: PropTypes.array.isRequired,
  RowTable: PropTypes.object.isRequired,
  isFooter: PropTypes.bool.isRequired,
  countRowsShow: PropTypes.number.isRequired,
  editStatus: PropTypes.func.isRequired,
  listPerPage: PropTypes.array.isRequired,
};

TableView.defaultProps = {
  isFooter: true,
}; 
export default TableView;