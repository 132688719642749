import React from "react";
import PropTypes from "prop-types";  
import { makeStyles } from "@material-ui/core/styles";
import  MuiTableBody from "@material-ui/core/TableBody";
import { 
   
  withStyles, 
} from "@material-ui/core";
/**
 * @param {Array} listRow The date
 */

const TableBody = withStyles({
  root: {
    borderBottom: "none", 
    borderSpacing:"0px",
    border:"4px solid transparent",
    outline:"none",
    borderRadius:"none",
    boxShadow:"none",
    minHeight:80

   
  }
})(MuiTableBody);

const BodyTable = (props) => {
  const { listRow,   RowTable } = props;

  return (
    <TableBody>
      
      {listRow && listRow.map((row,index) => (
        <RowTable  row={row} id_row={index}  />
      ))}
    </TableBody>
  );
};

BodyTable.propTypes = {
  listRow: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number.isRequired, 
  RowTable: PropTypes.object.isRequired,
  countRowsShow:PropTypes.number.isRequired, 
};
export default BodyTable;
