
//import logoImg from "../assets/images/logo.png";

import moment from 'jalali-moment'
export const isLoadingNeed = (timeLast) => {
    const timeNew = new Date().getMilliseconds()
    if (timeNew > timeLast) {
        const timeResult = timeNew - timeLast
        if (timeResult > 200)
            return true
        else return false
    }
    return false
}
export const timeLast = () => new Date().getMilliseconds()


export const setTheme = (setLogo) => {
    const theme_ = window.sessionStorage.getItem("webSiteInfo");
    let isSet = JSON.parse(window.sessionStorage.getItem("isSet"));
    
    const theme = JSON.parse(theme_) 
    if (!isSet && theme) { 
        window.sessionStorage.setItem("isSet", true)
        document.body.style.backgroundColor = theme.themeOptions["bg-color"];
        // document.getElementById(
        //     "background"
        // ).style.backgroundColor = ` linear-gradient(90deg, ${theme.themeOptions["bg-gradient-1"]} 0%,${theme.themeOptions["bg-gradient-2"]} 50%, ${theme.themeOptions["bg-gradient-1"]} 100%)`;
        document.getElementById(
            "background"
        ).style.backgroundColor = `#303030`;
 
    }
}


export const isPhone = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
//export const resulation=(window.screen.width * window.devicePixelRatio)+(window.screen.height * window.devicePixelRatio);
export const resulation=(window.screen.width  ) ;
export const getNumberCardFromatter = (number) => {
    
    return number.replace(/\W/gi, "").replace(/(.{4})/g,"$1 - ").slice(0, -3);
  };


  export const removeID_USER = (userId) => {
    
    if(userId && userId!=null ){
      if (  userId.length<4) {
        return userId.valueOf();
    }else{
      let u= userId.substring(0, userId.length-3) + "*" + userId.substring(userId.length-2);

      return u.substring(0, u.length-4) + "*" + u.substring(u.length-3);
    }
    

    }
   

  };

  export const getNumberCardFromatter2 = (number) => {
    
    return number.replace(/\W/gi, "").replace(/(.{4})/g,"$1\xa0\xa0").slice(0, -3);
  };
  export const getNumberCardFromatter3 = (number_) => {
    let number=""+number_
    if(number.length>14)
    return number.replace(/\W/gi, "").replace(/(.{4})/g,"$1-").slice(0, -1);
    else{ 
      return number =='undefined'? "-":number
    }
   
  };

  export const getShabaNumberFromatter = (number) => {
    
    
    return number.replace(/\W/gi, "").replace(/(.{4})/g , "$1  ") ;
  };

  export const getArrayNumberCard = (number) => {
      let numberCard= number.replace(/\W/gi, "").replace(/(.{4})/g, "$1 ").split(" ")
      numberCard.pop()
    return numberCard
  };

export const getListYear=()=>{
    let today = new Date().toLocaleDateString('fa-IR');
    let year=convertNumbers2English(today.split("/")?.[0])
    
    let listYear=[];
    for(let i=year;i<year+10;i++){ 
      let year_=`${i}`;
      listYear.push(year_.substring(2, 4)); 
    }
    return listYear
  }

export const getDate=(date_,time)=>{
  
   
  
  if(date_&& date_.length>3 && time&& time.length>2){
    let date=''
    if(date_!="0000-00-00")
     date=moment.from(date_, 'fa', 'YYYY-MM-DD').format('YYYY-MM-DD') 
    else{
      const today = new Date()
const yesterday = new Date(today)

yesterday.setDate(yesterday.getDate() - 1)
var year = yesterday.getFullYear();
var mes = yesterday.getMonth()+1;
var dia = yesterday.getDate();
date =year+"-"+(Number(mes)<10?"0"+mes: mes)+"-"+(Number(dia)<10?"0"+dia: dia) ;  
 
    }
    
    return new Date(date+"T"+time)
  }else
  return new Date()
  
}
export const convertNumberToPice = (number) => {
   
  if(number!=undefined && number!=""){
    let nStr = number.toString();
    nStr = nStr.replace(/\,/g, "");
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return (x1 + x2);
  }else{
    return "0"
  }
   
      
  };

  export const convertNumberToPiceInput = (number) => {
   
    if(number!=undefined && number!=""){
      let nStr = number.toString();
      nStr = nStr.replace(/\,/g, "");
      let x = nStr.split('.');
      let x1 = x[0];
      let x2 = x.length > 1 ? '.' + x[1] : '';
      let rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
          x1 = x1.replace(rgx, '$1' + ',' + '$2');
      }
      return (x1 + x2);
    }else{
      return ""
    }
     
        
    };

export const convertDateToFormat=(date)=>{

    date=date.replaceAll("-","/").replace(" ","\xa0\xa0\xa0\xa0\xa0\xa0");
    return date.substring(0,date.length-3)

}
export const convertDateToFormat2=(date)=>{

  if(date!=undefined && date.length>1) 
    date=date.replaceAll("-","/").replace(" ","\xa0\xa0");
    return date 
   



}
export const  convertNumbers2English=(string)=> {
    return Number(  string.replace(/[\u0660-\u0669]/g, function (c) {
        return c.charCodeAt(0) - 0x0660;
    }).replace(/[\u06f0-\u06f9]/g, function (c) {
       return c.charCodeAt(0) - 0x06f0;
   }));
}

export const  convertNumbers2EnglishString=(string)=> {
    return   string.replace(/[\u0660-\u0669]/g, function (c) {
        return c.charCodeAt(0) - 0x0660;
    }).replace(/[\u06f0-\u06f9]/g, function (c) {
       return c.charCodeAt(0) - 0x06f0;
   }) ;
}
 