import React, { useEffect } from "react";
import {
  makeStyles, 
  ThemeProvider,
  StylesProvider, 
} from "@material-ui/core/styles";
import {
  Select,
  Grid, 
} from "@material-ui/core";
 
import { DataHeader, rows } from "./DataTable";
import TableView from "./table/TableView";
import RowTable from "./table/body/RowTable"; 
import { connect } from "react-redux";
import {
  topMargin,
  jss, 
  theme1,
} from "./table/style/style";

import { red } from "@material-ui/core/colors"; 
import Api from "../../api/Api";
import { getHome  } from "../../redux/selectors";
import LoadingProcess from "../LoadingProcess2";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import "./table/style/styleDataPicker.css";
import moment from "moment-jalaali";
import DialogSuccess from "../../dialogs/DialogSuccess";
import DialogError from "../../dialogs/DialogError"; 
import { deleteLotteryAction,archiveLotteryAction } from "../../redux/actions";
 

const useStyles = makeStyles((theme) => ({
  root: {
   marginRight:10,
   marginLeft:10
  },
  
  
}));

  
 let countShowIndex;
 let totalPages;
const HomePage = ({ todos,archiveLotteryAction }) => {
  const classes = useStyles();
  const topMargin1 = topMargin(); 
  const [dateFrom, setDateFrom] = React.useState(null); 
  const [dateTo, setDateTo] = React.useState(null); 
  const [isFrist, setIsFrist] = React.useState(true);
  const [expanded, setExpanded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [expandedDate, setExpandedDate] = React.useState(false);
  const [data, setData] = React.useState([]); 
  const [isResult, setIsResult] = React.useState({
    result: false,
    msg: "",
    isSuccess: false,
  });

  const [openSnack, setOpenSnack] = React.useState({
    result: false,
    msg: "",
    isSuccess: false,
  }); 
 
 

  const editStatus = (result, msg) => {
    setIsResult({ result: true, msg: msg, isSuccess: result });
  };

  
  
 

  useEffect(()=>{ 
    document.title="لیست قرعه کشی های انجام شده"

 
  },[])

 

  useEffect(() => { 
     
   
   
     setLoading(true)
      setTimeout(() => {
        requestData(todos.page );
       }, 50);
   
  
  }, [todos.page]);


  useEffect(() => {
 
     
   if(todos.lottery   ){
     setLoading(true)
     if(todos.type=="delete")
    requestDeleteLottery(todos.lottery.idLottery,1,"delete")
    else
    requestDeleteLottery(todos.lottery.idLottery,todos.lottery.is_archive==1?0:1,"archive")
   }
  }, [todos.lottery]);


  


  const requestDeleteLottery = async (idLottery ,value_,type) => { 
    try {

      const data_ = new FormData(this); 
      data_.append("idLottery", idLottery); 
      data_.append("value_", value_);
      data_.append("type", type);
      const { data } = await Api.deleteLottery( data_); 
      
      if (data.success) {
        archiveLotteryAction(null)
      setIsResult({ result: true, msg: data.msg, isSuccess: true });
        


       // requestData(todos.page)
      } else {
        setIsResult({ result: true, msg: data.msg, isSuccess: false });
        
    setLoading(false)
      }
    } catch (error) {
      setIsResult({ result: true, msg: `${error}`, isSuccess: false });
      
    setLoading(false)
    }

   
  };

 



  const requestData = async (page ) => { 
    try {
  
      
      const data_ = new FormData(); 
      data_.append("page", page); 
      const { data } = await Api.getListLottery( data_); 
     
    
      countShowIndex= ( 15)  * ( (  page!=-1? page:1) - 1);
    
      if (data.success) {
        totalPages=data.totalPages;
         setData(data.lottery);
         
      } else {
        setIsResult({ result: true, msg: data.message, isSuccess: false });
      }
    } catch (error) {
      setIsResult({ result: true, msg: `${error}`, isSuccess: false });
    }

   setLoading(false)
   
  };






  const handleCloseDialogError = () => {
    setIsResult({ ...isResult, result: false });
  };

  const handleCloseDialogSuccess = () => {
    setIsResult({ ...isResult,result: false });
   
    requestData(todos.page );
  };
   
   
  return (
    <div className={classes.root}>


      <StylesProvider jss={jss}>
        <ThemeProvider theme={theme1}>
 
 
        <Grid container justify="center" direction="column"  justify="center"
              alignItems="center" xs={12} style={{marginBottom:20}}>
                {" "}
              <Grid item>
              <img
                  src={"./logo2.png"}
                  style={{
                    maxWidth: "180px",
                    maxHeight: "150px",
                    minHeight: "70px",
                    
                  }}
                />
              </Grid>
              <Grid item style={{color:"#000",fontFamily:"title2",fontSize:15,marginBottom:10}}>
              سامانه جامع قرعه کشی های آنلاین وب سایت چیتابت
              </Grid>
              </Grid>


              <DialogError
              isResult={isResult}
              handleCloseDialog={handleCloseDialogError}
            />
          <LoadingProcess loading={loading} />  
           {data && (
             <TableView
             rows={data}
             DataHeader={DataHeader}
             RowTable={RowTable}
             totalPages={totalPages}
             loading={loading}
             countRowsShow={countShowIndex}
           />
          )}


 
        </ThemeProvider>
      </StylesProvider>
   

      <div className="shapeDashBoard">
        
          <svg
            viewBox="0 0 500 85"
            //  preserveAspectRatio="none"
            style={{ height: "100%", width: "100%" }}
          >
            <path
              d="M0.00,49.98 C149.99,150.00 349.20,-49.98 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
              style={{ stroke: "none", fill: "#2196f3" }}
            ></path>
          </svg>
        </div>

   
    </div>
  );
};

const mapStateToProps = (state) => {
  const todos = getHome(state); 
  return { todos };
};

 
export default connect(mapStateToProps)(connect(null, { archiveLotteryAction,deleteLotteryAction })(HomePage)) ;

