

 

export const getAddLottery= store => {
    
    
    return store.AddLotteryReducer;
 
}


export const getHome= store => {
    
    
    return store.HomeReducer;
 
}