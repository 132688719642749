import {
    PAGE_TABLE_HOME,DELETE_LOTTERY,ARCHIVE_LOTTERY
} from '../actionTypes'


const infoState = {
    page: 1, 
    lottery:null,
    type:''
};

const HomeReducer = (state = infoState, action) => {

 
    switch (action.type) {
       
        case PAGE_TABLE_HOME: {
            const {
                page 
            } = action.payload;
            return {  
                page: page , 
                type:state.type,
                lottery:state.lottery
            };
        }
        case DELETE_LOTTERY: {
            const { 
                lottery, type
                
            } = action.payload;
            return { 
                lottery: lottery ,
                page:state.page,
                type:type
            };
        }

        case ARCHIVE_LOTTERY: {
            const { 
                lottery, type
                
            } = action.payload;
            return { 
                lottery: lottery ,
                page:state.page,
                type:type
            };
        }
        default: {
            return state;
        }
    }

}

export default HomeReducer;