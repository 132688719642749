import React from "react";
import PropTypes from "prop-types";
import {
  TablePagination,
  TableRow,
  TableFooter,
  Tooltip,
  TableCell,
} from "@material-ui/core";
import { makeStyles, styled, withStyles } from "@material-ui/core/styles";
import TablePaginationActions from "./TablePaginationActions";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Grid from "@material-ui/core/Grid";
import { focusDisable, togleBtn } from "../style/style"; 
 
import { connect } from "react-redux";
const useStyles = makeStyles({
 layoutFooter:{
   //border:"2px solid #141319"
 }

});

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 12,
    fontFamily: "IRANSans",
  },
}))(Tooltip);

const Footer = (props) => {
  const classes=useStyles()
  const { countColumn, countPage } = props;
  const setFoucus = focusDisable(); 
 
 

 
   

  return (
    <TableFooter className={classes.layoutFooter}>
      
      <TableRow>
        <TableCell align="center" colSpan={5}>
          <Grid container>
            <Grid item xs>
              <TablePaginationActions countPage={countPage} />
            </Grid>
 

       
          </Grid>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};
Footer.propTypes = {
  countColumn: PropTypes.number.isRequired,
  countPage: PropTypes.number.isRequired, 
};
 



 
export default Footer;
