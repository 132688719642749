import React from "react";
import PropTypes from "prop-types";  
import  MuiTableBody from "@material-ui/core/TableBody";
import { 
   
  withStyles, 
} from "@material-ui/core";
/**
 * @param {Array} listRow The date
 */

const TableBody = withStyles({
  root: {
    borderBottom: "none", 
    borderSpacing:"0px",
    border:"4px solid transparent",
    outline:"none",
    borderRadius:"none",
    boxShadow:"none",

   
  }
})(MuiTableBody);

const BodyTable = (props) => {
  const { listRow,  RowTable ,countRowsShow} = props;

  return (
    <TableBody>
       
      {listRow && listRow.map((row,index) => (
        <RowTable key={index} row={row} id_row={countRowsShow+index}  />
      ))}
    </TableBody>
  );
};

BodyTable.propTypes = {
  listRow: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number.isRequired, 
  RowTable: PropTypes.object.isRequired,
  countRowsShow:PropTypes.number.isRequired, 
};
export default BodyTable;
