export const DataHeader=[
    {
        title:"ردیف",
    },
    {
        title:"شناسه قرعه کشی ",
    },
    {
        title:"نام تورنومنت",
    },
    {
        title:" زمان انجام قرعه کشی",
    },
    {
        title:"  تعداد برنده  ",
    },
    {
        title:"مشاهده برندگان ",
    },

    
   
]
 