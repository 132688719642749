import React from 'react'
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {
    makeStyles, 
  } from "@material-ui/core/styles"; 




   const useStyles = makeStyles((theme) => ({
    
    header:{
       // background:"#2b2b2b",
       // borderRadius:"12px",
        maxHeight:"45px",
      //  margin:15,
        border:"none !important",
        outline:"none !important",
        marginBottom:"150px !important", 
        
    },
    layoutRow:{

    //  background:"transparent",
    background:"#2196f3",
      margin:"5px",
      borderRadius:"50px",
      padding:"5px",
    },
    label:{
      fontFamily:"IRANSans",
      fontSize:"13px !important",
      color:"#fff",
      maxHeight:"30px",
      textAlign:"center",
     // borderRadius:"15px",
     // background:"transparent"
     // padding:0,
    //  paddingLeft:5, 
    },
  }));

/**
 * @param {Array} listHeader The date
 */
const header=(index)=>{
 switch(index){
    
 
  case 7:
    return {
      padding:0,
      paddingRight:"15px",
    }
    case 9:
  case 6:
    return {padding:0}
   case 1 :
     return {padding:0}
     default:
     return {}
 }
}
const HeaderTable=(props )=> {
    
    const calsses=useStyles()
        const {listHeader,theme} =props;
    return(
        <TableHead  className={calsses.header}>
          <TableRow className={calsses.layoutRow}>
              {
                  listHeader.map((info,index)=>{
                      return(
                      <TableCell className={`${calsses.label} un-selected`} variant="head" key={index}  style={{padding:0}}>
                          
                          {info.title}</TableCell>
                      )

                  })
              } 
           
          </TableRow>
        </TableHead>
    );
}


HeaderTable.propTypes = {
    listHeader: PropTypes.array.isRequired,
    theme:PropTypes.object.isRequired
     
  };
export default HeaderTable;