import {  
  RELOAD_PAGE,
  ADD_WINNER,
  DELETE_WINNER,
  EDIT_WINNER,
  PAGE_TABLE_HOME,
  DELETE_LOTTERY,
  ARCHIVE_LOTTERY

} from "./actionTypes";
 

  export const updateReloadPage= ( isReload) => ({
    type: RELOAD_PAGE,
    payload: { 
      isReload: isReload
    }
  });

  export const addWinnerAction= ( winner,type="add") => ({
    type: ADD_WINNER,
    payload: { 
      winner: winner,
      type:type
    }
  });
  export const editWinnerAction= (   winner,type="edit") => ({
    type: EDIT_WINNER,
    payload: { 
      winner: winner,
      type:type
    }
  });

  export const deleteWinnerAction= (   winner,type="delete") => ({
    type: DELETE_WINNER,
    payload: { 
      winner: winner,
      type:type
    }
  });


  export const changePageHome= ( page) => ({
    type: PAGE_TABLE_HOME,
    payload: { 
      page: page,
      
    }
  });


  export const deleteLotteryAction= (   lottery ,type="delete") => ({
    type: DELETE_LOTTERY,
    payload: { 
      lottery: lottery, 
      type:type
    }
  });


  export const archiveLotteryAction= (   lottery ,type="archive") => ({
    type: ARCHIVE_LOTTERY,
    payload: { 
      lottery: lottery, 
      type:type
    }
  });