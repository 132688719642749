
 
export const DataHeader=[
    {
        title:"#",
    },
    {
        title:"شناسه کاربری",
    },
    {
        title:"جایزه ",
    },
    
    
]
export const message=   {
    normalBtn1:'انتقال به صف پرداخت CPG عادی',
    priorBtn1:'انتقال به صف اولویت ها',
    normalBtn2: 'حذف از صف پرداخت',
    
    } 
 