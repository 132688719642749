import React, { useEffect } from "react";
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
  StylesProvider,
  jssPreset,
  styled,
} from "@material-ui/core/styles";
import {
  Select,
  Grid,
  Table,
  Paper,
  TextField,
  FormControl,
  Collapse,
  Chip,
  MenuItem,
  Dialog,
} from "@material-ui/core";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import { DataHeader, rows } from "./DataTable";
import TableView from "./table/TableView";
import RowTable from "./table/body/RowTable";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Container from "@material-ui/core/Container";
import { connect } from "react-redux";
import Slide from "@material-ui/core/Slide";
import {
  topMargin,
  jss,
  tableStyle,
  headerStyle,
  theme1,
} from "./table/style/style";

import { red } from "@material-ui/core/colors";

import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { DatePicker } from "jalali-react-datepicker";
import Api from "../../../api/Api";
import { getUpdateTableShowWidthdrawal } from "../../../redux/selectors";
import LoadingProcess from "../../LoadingProcess2";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import "./table/style/styleDataPicker.css";
import moment from "moment-jalaali"; 
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="down"
      ref={ref}
      {...props}
      timeout={{ enter: 450, exit: 450 }}
    />
  );
});
const useStyles = makeStyles((theme) => ({

  dialogLayout: {
    "& div>.MuiDialog-paperScrollPaper": {
      overflowY: "auto !important",
      background: "#fff",
    },
    "& div>.MuiDialog-paperWidthSm": {
      minHeight: "300px !important",
    },

    background: "#2196f310",
  },
  root: {
   // margin: 10,
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontFamily: "IRANSans",
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: theme.spacing(1),
    fontFamily: "IRANSans",
    "&:focus": {
      outlineStyle: "none!important",
    },
    "& span": {
      color: "#4caf50",
    },
    background: "#4caf5030",
    "&:hover": {
      background: "#4caf5040",
    },
  },
  submit2: {
    marginTop: theme.spacing(1),
    fontFamily: "IRANSans",
    "&:focus": {
      outlineStyle: "none!important",
    },
    "&:hover": {
      background: "#d32f2f40",
    },
    "& span": {
      color: "#d32f2f",
    },
    background: "#d32f2f30",
  },
  label: {
    fontFamily: "IRANSans",
  },
  layoutSearch: {
    background: "#f0f0f0",
    color: "#2e2e2e",
    padding: theme.spacing(0.7),
    borderRight: "5px solid #0ea960",
    borderRadius: "5px",
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginTop: 0,
  },
  layoutTable: {
    background: "#141319",
  },
}));


 

 

 
const WinnerDialog = ({  handleCloseDialog,idLottery,showDialog ,isShowBtn,withdrawal_Id,cPG_Withdrawal_TransactionCount_List,newStatus,RemainedCount}) => {
  const classes = useStyles(); 
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(()=>{

    if(showDialog){
     setLoading(true)
      requestData(idLottery)
    }
   
  },[idLottery,showDialog])

  const requestData = async (idLottery ) => { 
    try {

      const data_ = new FormData(this); 
      data_.append("idLottery", idLottery); 
      const { data } = await Api.getListWinner( data_); 
       
      if (data.success) {
      
        
        setData(data.lottery); 
      } else {
      //  setIsResult({ result: true, msg: data.message, isSuccess: false });
      }
    } catch (error) {
      //setIsResult({ result: true, msg: `${error}`, isSuccess: false });
    }

   setLoading(false)
   
  };


  
  return (
    <Dialog
    open={showDialog}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleCloseDialog }
    className={classes.dialogLayout}
  >
    <div className={classes.root}>
      
            <div style={{direction:"rtl"}}>
             
       
              <TableView
                rows={data}
                DataHeader={DataHeader}
                RowTable={RowTable} 
                handleCloseDialog={handleCloseDialog}
                
              />

<LoadingProcess loading={loading} />
            </div>
         
    </div>
    </Dialog>
  );
};

 
export default  WinnerDialog ;
