import React from "react";
import PropTypes from "prop-types";
import { footerViewStyle, focusDisable } from "../style/style";
import Pagination from "@material-ui/lab/Pagination";
import "../../../../styles/app.css";
import { changePageHome } from "../../../../redux/actions";
import { connect } from "react-redux"; 

function TablePaginationActions(props) {
  const classes = footerViewStyle();
  const focusDisable1 = focusDisable();
  const { countPage, changePageHome ,refresh} = props;

  const [currentPage, setCurrentPage] = React.useState(1);
  const handleChange = (event, value) => {
    setCurrentPage(value);
    changePageHome(value);
  };
 

  return (
    <div className={classes.root}>
      <Pagination
        dir="rtl"
        color="secondary"
        variant="outlined"
        className={focusDisable1.ul}
        count={countPage}
        page={currentPage > countPage ? 1 : currentPage}
        onChange={handleChange}
      />
    </div>
  );
}

TablePaginationActions.propTypes = {
  countPage: PropTypes.number.isRequired,
};
 
 
export default  
  connect(null, { changePageHome })(TablePaginationActions)
 
