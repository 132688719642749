import React from "react";
import PropTypes from "prop-types";
import { makeStyles, styled } from "@material-ui/core/styles";

import {
  TableRow,
  IconButton,
  Chip,
  withStyles,
  Tooltip,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import AttachmentIcon from '@material-ui/icons/Attachment'; 
import {
  convertNumberToPice,
  getNumberCardFromatter3,
  removeID_USER,
} from "../../../../../tools/Tools";
import MuiTableCell from "@material-ui/core/TableCell";
import { tableRowOne, focusDisable, unSelect } from "../style/style";  


 


const getStatus2 = (status) => {
  if (status == 1) {
    return 'تسویه حساب دستی';
  }  else if (status == 2) {
    return  'تسویه حساب سیستمی';
  } else {
    return  'تسویه حساب دستی و سیستمی';
  }
};




const getStatus = (status) => {
  if (status == 0) {
    return "در حال پرداخت";
  } else if (status == 1) {
    return "پرداخت ناقص";
  } else if (status == 2) {
    return "پرداخت شده";
  } else {
    return "رد شده";
  }
};

const getStatusClass = (status) => {
  if (status == 0) {
    return "status3";
  } else if (status == 1) {
    return "status0";
  } else if (status == 2) {
    return "status1";
  } else {
    return "status2";
  }
};




const TableCell = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid rgba(173, 173, 173, 0.3)",
  },
}))(MuiTableCell);
const TableRow1 = withStyles((theme) => ({
  tableRow: {
    // "& th,  td": {
    //   borderBottom: 0,
    //   background:'#000',
    // },
    // "&:hover ": {
    //   borderRight: '10px solid  #000',
    // },
  },
}))(TableRow);

const useRowStyles = makeStyles({
  root: {
    background: "#f0f0f0",
    WebkitTransition: "background-color 100ms linear",
    msTransition: "background-color 100ms linear",
    transition: "background-color 100ms linear",
    // border:"1px solid #f7f7f7",

    "& th,  td": {
      borderBottom: 0,
    },
    "&:hover": {
      //  background: "rgba(245, 0, 87, 0.12)",
      //  border:"1px solid rgba(245, 0, 87, 0.15)",
      //  borderBottom:"1px solid white",
    },
    "& > *": {
      fontSize: 12,
      fontFamily: "title2",
      color: "dark",
      top: 0,
      bottom: 0,
    },
  },
  label: {
    color: "#000",
  },
});

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  tableRow: {
    background: "#000",
    "& th,  td": {
      borderBottom: 0,
    },
  },
});

const useRowStyles2 = makeStyles({
  root: {
    background: "transparent",
    //  border:"1px solid #fff",
    ///  border:"1px solid white",
    WebkitTransition: "background-color 100ms linear",
    msTransition: "background-color 100ms linear",
    transition: "background-color 100ms linear",
    borderBottom: "1px solid transparent",
    minHeight: "50px !important",

    "& th,  td": {
      // borderBottom: 0,
    },
    "&:hover": {
      // background: "rgba(245, 0, 87, 0.12)",
      //  background: "green",
      // border:"1px solid rgba(245, 0, 87, 0.15)",
      // borderBottom:"1px solid #f7f7f7",
    },
    "& > *": {
      fontSize: 11,
      fontFamily: "title2",
      color: "#000",
    },
  },
  label: {
    color: "#000",
  },
});

 

const RowTable = (props) => {
  const { row,   id_row,   } = props; 
  // const classes = styles();
  const rowS = useRowStyles2();
  const rowS2 = useRowStyles();
  const fucuseDis = focusDisable();
  const unSelect1 = unSelect();

 

  return (
    <React.Fragment>
       
      <TableRow className={(id_row+1)%2==0? rowS.root:rowS2.root}>
        <TableCell
          align="center"
          size="medium"
          className={`  fontSizeCellDiposit`}
          style={{fontSize:"14px",color:"#000"}}
        >
          {id_row + 1}
        </TableCell>
        <TableCell
          align="center"
          
          className="fontSizeCellDiposit"
          style={{fontSize:"14px",direction: "ltr" ,color:"#000"}}
        >
           
          {(row.userId && row.userId.length>3) ? removeID_USER(row.userId) : row.userId }
        </TableCell>
        <TableCell
          align="center" 
          className="fontSizeCellDiposit"
          style={{fontSize:"14px",color:"#000"}}
        >
          {row.award}
        </TableCell>
      
       
        
       
      </TableRow>
       
 
    </React.Fragment>
  );
};

RowTable.propTypes = {
  id_row: PropTypes.number.isRequired,
  row: PropTypes.object.isRequired
};

export default withStyles(styles)(RowTable);
